<template>
<div class="content-panel">
  <el-row :gutter="10" type="flex" justify="space-between" align="middle" class="breadcrumb-wrap">
    <el-col :span="20">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">{{msg('首页')}}</el-breadcrumb-item>
        <el-breadcrumb-item>{{msg('物流装袋信息管理')}}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="4" style="text-align:right;">
      <el-button type="success" size="small" icon="el-icon-refresh" @click="refresh()" circle></el-button>
    </el-col>
  </el-row>

  <el-row :gutter="10"  class="query-wrap" :style="{height: isQueryExpend?'auto':'55px'}">
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('袋号')" v-model="query.pkgCode" size="small">
          <i slot="prefix" class="el-input__icon el-icon-suitcase"></i>
        </el-input>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-select v-model="query.allowUploadPage" size="small" class="query-select" placeholder="是否允许上传面单" clearable>
          <el-option v-for="p in dicts.allowUploadPages1" :key="p.value" :label="p.label" :value="p.value"></el-option>
        </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.Date"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('打包开始时间')"  value-format="yyyy-MM-dd"
            :end-placeholder="msg('打包结束时间')"
            size="small"
          >
        </el-date-picker >
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.SendDate"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('发货开始时间')"  value-format="yyyy-MM-dd"
            :end-placeholder="msg('发货结束时间')"
            size="small"
          >
        </el-date-picker>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.ZhuangchuanDate"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('离境开始时间')"  value-format="yyyy-MM-dd"
            :end-placeholder="msg('离境结束时间')"
            size="small"
          >
        </el-date-picker>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-date-picker  v-model="query.QingguanDate"  type="daterange"  :range-separator="msg('至')" 
            :start-placeholder="msg('清关开始时间')"  value-format="yyyy-MM-dd"
            :end-placeholder="msg('清关结束时间')"
            size="small"
          >
        </el-date-picker>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
       <el-select v-model="query.hasStorePkg" size="small" class="query-select" placeholder="打包类型" clearable>
          <el-option v-for="p in dicts.hasStorePkgs1" :key="p.value" :label="p.label" :value="p.value"></el-option>
        </el-select>
    </el-col>
    <el-col :xs="24" :sm="8" :lg="6" :xl="4">
        <el-input  :placeholder="msg('创建人')" v-model="query.createrName" size="small">
          <i slot="prefix" class="el-input__icon el-icon-suitcase"></i>
        </el-input>
    </el-col>
  </el-row>

  <vxe-toolbar id="toolbar11" :custom="{storage: true}">
    <template v-slot:buttons>
      <el-button-group>
        <el-button type="primary" size="mini" @click="add()" v-if="true || hasPermission('expPkg:create')">
          <i class="el-icon-circle-plus-outline" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('添加')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="edit()" v-if="hasPermission('expPkg:update')">
          <i class="el-icon-edit" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('编辑')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="notifyUpPage(1)" v-if="hasPermission('expPkg:notifyUpPage')">
          <i class="el-icon-bell" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('通知上传面单')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="notifyUpPage(0)" v-if="hasPermission('expPkg:notifyUpPage')">
          <i class="el-icon-bell" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('取消通知上传面单')}}</span>
          </transition>
        </el-button>
        <el-button type="primary" size="mini" @click="uploadUpdate()" v-if="hasPermission('expPkg:iptUpdate')">
          <i class="el-icon-upload2" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('导入信息')}}</span>
          </transition>
        </el-button>
       
        <el-button type="primary" size="mini"  v-popover:dpopover  v-if="hasPermission('expPkg:updateStatus')">
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('手动更新托运信息')}}</span>
          </transition>
          <i class="el-icon-arrow-down el-icon--right" style="display:inline-block;margin-left:4px;"></i>
        </el-button>
        <el-button type="danger" size="mini" @click="deleted()" v-if="hasPermission('expPkg:delete')">
          <i class="el-icon-delete" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('删除')}}</span>
          </transition>
        </el-button>
      </el-button-group>
    </template>
    <template slot="tools">
      <el-button-group>
        <el-button type="success" @click="getExpPkgs(true)" size="mini">
          <i class="el-icon-search" style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('搜索')}}</span>
          </transition>
        </el-button>
        <el-button type="info" size="mini" @click="expendQuery()">
          <i class="el-icon-arrow-down" v-if="!isQueryExpend" style="display:inline-block;margin-right:4px; "></i>
          <i class="el-icon-arrow-up"  v-else style="display:inline-block;margin-right:4px; "></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('高级')}}</span>
          </transition>
        </el-button>
        <el-button type="success" size="mini" @click="$refs.dataTable.exportData({ type: 'xlsx',filename: msg('包裹信息下载'),sheetName: 'Sheet1' })" v-if="hasPermission('express:download')">
          <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('导出')}}</span>
          </transition>
        </el-button>
         <el-button type="success" size="mini" @click="download()" v-if="hasPermission('expPkg:download')">
          <i class="el-icon-download" style="display:inline-block;margin-right:4px;"></i>
          <transition name="el-fade-in-linear">
            <span v-show="!isMinScreen">{{msg('下载清关资料')}}</span>
          </transition>
        </el-button>
      </el-button-group>
     </template>
  </vxe-toolbar>
  
  <el-popover placement="bottom" width="150" trigger="click" ref="dpopover">
    <div class="status-list">
      <a @click="updateStatus(0)">{{msg('发货')}}</a>
      <a @click="updateStatus(1)">{{msg('离境')}}</a>
      <a @click="updateStatus(2)">{{msg('清关')}}</a>
      <a @click="updateStatus(3)">{{msg('提货')}}</a>
    </div>
  </el-popover>

  <div class="table-wrap">
    <vxe-table style="width: 100%" :height="docHeight" ref="dataTable"
      border="inner" size="small"  stripe resizable  :data="expPkgs" 
      show-overflow 
      highlight-hover-row highlight-current-row highlight-hover-column highlight-current-column
    >
      <vxe-table-column type="checkbox"  min-width="55" :fixed="isMinScreen?null: 'left'"></vxe-table-column>
      <vxe-table-column min-width="90" :fixed="isMinScreen?null: 'left'"
          field="id" :title="msg('ID')"
          sortable :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
        ></vxe-table-column>
        <vxe-table-column min-width="120"
          field="created" :title="msg('打包时间')"    
          sortable :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
        ></vxe-table-column>
      <vxe-table-column min-width="120" :show-overflow-tooltip="true"
          field="pkgCode" :title="msg('袋号')" 
          sortable :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
        ></vxe-table-column>
      <vxe-table-column min-width="120"
          field="hasStorePkg" :title="msg('打包类型')" sortable 
          :filters="dicts.expPkgTypes1" 
        >
        <template v-slot="{ row }">
          <span>
            <el-tag :type="dicts.cssTypes[row.hasStorePkg]" disable-transitions>
              {{dicts.expPkgTypes2[row.hasStorePkg]}}
            </el-tag>
          </span>
        </template>
      </vxe-table-column>
      <vxe-table-column min-width="100" field="status" :title="msg('状态')" 
          sortable :filters="dicts.expPkgStatus1" 
        >
        <template v-slot="{ row }">
          <span>
            <el-tag :type="dicts.cssTypes[row.status]" disable-transitions>
              {{dicts.expPkgStatus[row.status]}}
            </el-tag>
          </span>
        </template>
      </vxe-table-column>
      <vxe-table-column  min-width="130" 
          field="allowUploadPage" :title="msg('允许上传面')" sortable
          :filters="dicts.allowUploadPages1" 
        >
         <template v-slot="{ row }">
          <span>
            <el-button v-if="row.allowUploadPage == 0"  type="warning" size="mini">不允许</el-button>
            <el-tag v-else type="success" disable-transitions>允许</el-tag>
          </span>
        </template>
      </vxe-table-column>
      <vxe-table-column min-width="120" field="weight" :title="msg('重量')"
        sortable :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
      ></vxe-table-column>
      <vxe-table-column min-width="120"
          field="total" :title="msg('总票数')"
          sortable :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
        ></vxe-table-column>
      <vxe-table-column min-width="150"
          field="totalPage" :title="msg('已提供面单数')" sortable
         :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
        ></vxe-table-column>
      <vxe-table-column min-width="150"
          field="totalUnPage" :title="msg('未提供面单数量')" 
           sortable :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
        ></vxe-table-column>
      <vxe-table-column min-width="150"
          field="totalPrinted" :title="msg('已打印面数')"
           sortable :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
        ></vxe-table-column>
      <vxe-table-column min-width="150"
          field="totalUnPrinted" :title="msg('未打印面单数')" 
           sortable :filters="[{data: {type: 'has', isCase: false, name: ''}}]" :filter-render="{name: 'FilterComplex'}"
        ></vxe-table-column>
      <vxe-table-column min-width="120"
        field="sendDate" :title="msg('发货时间')" sortable
        :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
      ></vxe-table-column>
      <vxe-table-column min-width="120"
          field="zhuangchuanDate" :title="msg('离境时间')" sortable
          :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
        ></vxe-table-column>
      <vxe-table-column min-width="120"
          field="qingguanDate" :title="msg('清关时间')" sortable
          :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
        ></vxe-table-column>
      <vxe-table-column min-width="120"
          field="pickUpDate" :title="msg('提货时间')" sortable
          :filters="[{data: []}]" :filter-render="{name: 'ElDatePicker', props: {type: 'daterange', rangeSeparator: '至', startPlaceholder: '开始日期', endPlaceholder: '结束日期'}}"
        ></vxe-table-column>
      <vxe-table-column  min-width="100"
          field="createrName" :title="msg('创建人')" sortable
          :filters="[{data: {vals: [], sVal: ''}}]" :filter-render="{name: 'FilterContent'}"
        ></vxe-table-column>
      
    </vxe-table>

    <el-pagination id="xpagination"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :background="true"
      :current-page.sync="currentPage"
      :page-sizes="[10,20 ,50, 100, 200, 500, 1000,2000, 5000,10000,20000,50000]"
      :page-size="query.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total">
    </el-pagination>
  </div>

  <el-dialog :append-to-body="true" :title="dglogTitle" :visible.sync="dialogTableVisible" :fullscreen="true">
    <exp-pkg-edit :id="currId" :expPkg="currExpPkg" v-on:closeDg="closeEditDialog" ref="expPkgEdit"/>
  </el-dialog>

  <el-dialog :append-to-body="true" title="请输入日期" :visible.sync="dialogIptDateVisiable" width="300" :fullscreen="isMinScreen">
    <div style="text-align:center;">
      <el-date-picker v-model="statusDate" type="date" placeholder="选择日期" value-format="yyyy-MM-dd"></el-date-picker>
      <div style="margin-top:20px; margin-bottom:20px;">
         <el-button type="success" @click="doUpdateStatus()" size="mini">
          <i class="el-icon-check" style="display:inline-block;margin-right:4px; "></i>
          <span >{{msg('确定更新状态')}}</span>
        </el-button>
        <el-button type="info" size="mini" @click="dialogIptDateVisiable = !dialogIptDateVisiable">
          <i class="el-icon-close" style="display:inline-block;margin-right:4px; "></i>
          <span>{{msg('取消')}}</span>
        </el-button>
      </div>
    </div>
  </el-dialog>

  <el-dialog :title="msg('上传重量')" :visible.sync="dialogImportVisible" :fullscreen="isMinScreen"  width="600px" @opened="hendleOpenedImport()" >
    <pkg-update-import ref="PkgUpdateImportRef" @upload-success="getExpPkgs()"/>
  </el-dialog> 

</div>
</template> 

<script>
// @ is an alias to /src
import ExpPkgEdit from "./ExpPkgEdit.vue";
import ExpPkgUpdateImport from "./ExpPkgUpdateImport.vue";

export default {
  name: 'ExpPkg',
  components: {
    ExpPkgEdit: ExpPkgEdit,
    PkgUpdateImport : ExpPkgUpdateImport,
  },
  mounted:function(){
    var user = JSON.parse(window.localStorage.getItem("user"));
    this.userName = user.username;
    var _this = this;
    this.columns = this.$kit.getColumns('exp-pkg-filter');
    this.getExpPkgs();
  },
  data(){
    return{
        query:{  //查询条件
          start: 0,
          length: 10,
          pkgCode: "",
          Date: [], 
          PickUpDate: [], 
          ZhuangchuanDate: [], 
          QingguanDate: [], 
          SendDate: [], 
          Packaged: [], 
          hasStorePkg:null,
          createrName:null,
        },
        currentPage: 1,   //分页的当前页
        expPkgs: [],   //表格数据列表
        dglogTitle: this.msg('编辑物流装袋信息'),
        isQueryExpend: this.$store.state.isQueryExpend,  //查询框是否展开
        total: 0,//总记录数,
        currId: null,   //当前列表中选中的id
        dialogTableVisible: false, //编辑详情的隐藏与显示,
        columns: [],  //字段是否显示
        dialogImportVisible: false,
        currExpPkg:null,

        dialogIptDateVisiable: false,
        statusDate: null,
        statusType: null,
        selectPkgIds: null,
        userName:null,
    }
  }, 
  computed: {
    isMinScreen: {  //是否小屏幕，浏览器
        get() {
          return this.$store.state.isMinScreen;
        }
    },
    docHeight: {  //表格的高度
        get() {
          var height =  this.$store.state.docHeight;
          return height - 320;
        }
    }
  },
  methods: {
    getExpPkgs: function(start){ //获取列表
      var _this = this;

      var param = this.$kit.rebuildQuery(this.query);

      if(start){
        param.start = 0;
        this.currentPage = 1;
      }

      this.axios.post(this.$kit.api.expPkgList, param).then(function (result) {
        if(result.status){
          if(_this.expPkgs.length>0)
            _this.expPkgs.splice(0, _this.expPkgs.length);
          
          _this.total = result.data.recordsTotal;
          var expPkgDatas = result.data.data;

          for(var i=0; i<expPkgDatas.length; i++){

            var item = expPkgDatas[i];
            if(item.created){
              item.created = item.created.substring(0,10);
            }
            if(item.zhuangchuanDate){
              item.zhuangchuanDate = item.zhuangchuanDate.substring(0,10);
            }
            if(item.qingguanDate){
              item.qingguanDate = item.qingguanDate.substring(0,10);
            }
            if(item.pickUpDate){
              item.pickUpDate = item.pickUpDate.substring(0,10);
            }
            if(item.sendDate){
              item.sendDate = item.sendDate.substring(0,10);
            }
          
            _this.expPkgs.push(item);
          } 

        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
        console.error(error);
      });

    },//end function
    refresh: function(){  //刷新列表
      this.$kit.clearQuery(this.query);
      this.currentPage = 1;
      this.getExpPkgs();
    },
    expendQuery: function(){  //展开与收缩查询框
        this.isQueryExpend = !this.isQueryExpend;
    },
    handleSizeChange(val) {  //分页的每页数据变化后的回调事件
      //console.log(`每页 ${val} 条`);
      this.query.length = val;
      this.query.start = 0;
      this.getExpPkgs();
    },
    handleCurrentChange(val) {   //分页的当前页改变后的回调事件
      //console.log(`当前页: ${val}`);
      this.query.start = this.query.length * (val -1);
      this.getExpPkgs();
    },
    add(){  //添加
      this.currId = null;
      this.currExpPkg = {
        pkgCode: null,
        zhuangchuanDate: null,
        qingguanDate: null,
        pickUpDate:null,
        sendDate: null, 
        hasSend: 0,
        orders: [],
      };
      this.dglogTitle = this.msg("添加新物流装袋信息");
      
      // var expPkgEdit = this.$refs.expPkgEdit;
      // if(expPkgEdit){
      //   expPkgEdit.initForm(null);
      // }

      this.dialogTableVisible = true;
    },
    edit(){  //修改
      var _this = this;
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      if(selectes.length>1){
        this.$message(this.msg('只能操作一条数据'));
        return;
      }

      //如果物流状态为已发货，则不允许修改任何信息
      if(this.userName != "admin"){
        if(selectes[0].hasSend == 1){
            this.$message(this.msg('该订单已发货，不允许修改'));
            return;
        }
      }

      this.axios.get(this.$kit.api.expPkgDetail+"?id="+selectes[0].id).then((result) => {
        if(result.status){
          _this.currExpPkg = result.data;
          _this.currExpPkg.created;
          _this.currId = _this.currExpPkg.id;
          _this.dglogTitle = this.msg("编辑物流装袋信息");
          _this.dialogTableVisible = true;
        }else{
          _this.$message(result.msg);
        }
        
      }).catch(function (error) {
          console.error(error);
      });

    },
    sendPkg(pkg){
      this.$confirm(this.msg('确定要发货吗?'), this.msg('提示'), {
        confirmButtonText: this.msg('确定'),
        cancelButtonText: this.msg('取消'),
        type: 'warning'
      }).then(() => {
        if(!pkg.pkgCode){
          this.$message(this.msg("您还没有更新袋号，请更新袋号后，再发货"));
          return;
        }

        this.axios.get(this.$kit.api.expPkgStatus+"?id="+pkg.id+"&status=0").then((result) => {
          this.$message(result.msg);
          if(result.status){
            this.getExpPkgs();
          }
        }).catch(function (error) {
          console.error(error);
        });
      });
    },

    closeEditDialog(type){  //编辑弹窗关闭后的回调事件
      if(type){
        this.getExpPkgs();
      }
      this.dialogTableVisible = false;
    },
    deleted(){  //删除
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      var ids = selectes.map( o => o.id);
      
      var _this = this;

      this.$confirm(this.msg('确定要删除该物流装袋信息吗?'), this.msg('提示'), {
          confirmButtonText: this.msg('确定'),
          cancelButtonText: this.msg('取消'),
          type: 'warning'
        }).then(() => {
          _this.$axios.post(_this.$kit.api.expPkgDelete, ids)
            .then(function(result){
              _this.$message(result.msg || _this.msg('删除成功'));
              _this.getExpPkgs();
          });
        });
    },
    //更新状态
    updateStatus(command){
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      var type = "";
      if(command=="0"){
        type = "发货";
      }else if(command=="1") {
        type="离境";
      }else if(command=="2"){
        type="清关";
      }else if(command=="3"){
        type="提货";
      }

      this.$confirm(this.msg('确定要更新为'+type+'状态吗?'), this.msg('提示'), {
        confirmButtonText: this.msg('确定'),
        cancelButtonText: this.msg('取消'),
        type: 'warning'
      }).then(() => {

        var ids = selectes.map(e => e.id);

        this.statusType = command;
        this.selectPkgIds = ids;

        this.dialogIptDateVisiable = true;

      });
    },
    doUpdateStatus(){

      if(!this.statusDate){
         this.$message(this.msg('日期不能为空'));
         return;
      }

      this.axios.get(this.$kit.api.expPkgStatus+"?ids="+this.selectPkgIds+"&status="+this.statusType+"&date="+this.statusDate).then((result) => {
          
        if(result.msg){
          var msgs = result.msg.split(",").join("<br/><br/>");
          this.$message({
            dangerouslyUseHTMLString: true,
            message: msgs
          });
          
        }
        if(result.status){
          this.getExpPkgs();
        }

        this.statusDate = null;
        this.dialogIptDateVisiable = false;
        this.selectPkgIds = null;
        this.statusType = null;
      }).catch(function (error) {
        console.error(error);
        this.statusDate = null;
      });
    },
    notifyUpPage(status){
      var selectes = this.$refs.dataTable.getCheckboxRecords();
      if(selectes.length==0){
        this.$message(this.msg('请选中一条数据进行操作'));
        return;
      }

      var ids = selectes.map(e => e.id);
      this.axios.get(this.$kit.api.expPkgNotify+"?ids="+ids.join(",")+"&s="+status).then((result) => {
        this.$message(result.msg);
        if(result.status){
          this.getExpPkgs();
        }
      }).catch(function (error) {
        console.error(error);
      });

    },
    uploadUpdate(){
      this.dialogImportVisible = true;
    },
    //下载清关资料
    download(){
      var _this = this;
      var param = this.$kit.rebuildQuery(this.query);
      window.location.href= this.$kit.api.expPkgDownload+"?data="+encodeURIComponent(JSON.stringify(param));
    },
    //导出列表
    downloadTable(){
      var _this = this;
      var param = this.$kit.rebuildQuery(this.query);
      window.location.href= this.$kit.api.expPkgDownloadTable+"?data="+encodeURIComponent(JSON.stringify(param));
    },
    hendleOpenedImport(){
      this.$nextTick(() => {
        this.$refs.PkgUpdateImportRef.initForm();
      });
    }
  }
}
</script>

<style class="scss" scoped>
.status-list a{display:block; padding:8px; margin:5px 0px; border-bottom:1px solid #ddd; cursor:pointer;}
.status-list a:hover{background: #eee; }
</style>
