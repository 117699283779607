<template>
<el-form ref="form" :model="expPkg" label-width="80px">
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('袋号')">
                <el-input v-model="expPkg.pkgCode"></el-input>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('发货状态')">
                <el-switch style="display: block; margin-top: 9px;" v-model="expPkg.hasSend"
                    active-color="#ff4949"  inactive-color="#13ce66"
                    active-text="已发货" inactive-text="未发货"
                    :active-value="1" :inactive-value="0">
                </el-switch>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('发货时间')">
                <el-date-picker v-model="expPkg.sendDate" type="datetime" placeholder="选择日期"
                    :disabled="!(expPkg.hasSend && expPkg.sendDate)" >
                </el-date-picker>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('离境时间')">
                <el-date-picker v-model="expPkg.zhuangchuanDate" type="datetime" placeholder="选择日期"
                    :disabled="true" >
                </el-date-picker>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('清关时间')">
                <el-date-picker v-model="expPkg.qingguanDate" type="datetime" placeholder="选择日期"
                    :disabled="true" >
                </el-date-picker>
            </el-form-item>
        </el-col>
    </el-row>
    <el-row>
        <el-col :xs="24" :sm="12">
             <el-form-item :label="msg('提货时间')">
                <el-date-picker v-model="expPkg.pickUpDate" type="datetime" placeholder="选择日期"
                    :disabled="true" >
                </el-date-picker>
            </el-form-item>
        </el-col>
        <el-col :xs="24" :sm="12">
            <el-form-item :label="msg('创建时间')">
                <el-date-picker v-model="expPkg.created" type="datetime" placeholder="选择日期"
                    :disabled="true" >
                </el-date-picker>
            </el-form-item>
        </el-col>
    </el-row>
  
    <div style="text-align:center;">
        <el-button type="primary" size="mini"  @click="onSubmit"><i class="el-icon-check"></i><span>{{msg('确定')}}</span></el-button>
        <el-button  size="mini" v-on:click="$emit('closeDg')">{{msg('取消')}}</el-button>
    </div>
</el-form> 
</template>

<script>
export default {
  name: 'ExpPkgEdit',
  data(){
    return{
       
    }
    
  }, 
  computed:{
      mode: {
          get(){
              return this.id==null;
          }
      }
  },
  props:{
      id:null,
      expPkg:Object,
  },
  mounted: function(){
        var _this = this;
       
  },
  methods:{
    onSubmit(){
        var url = null;
        if(this.id==null){
            url = this.$kit.api.expPkgCreate;
        }else{
            url = this.$kit.api.expPkgUpdate;
        }

        var _this = this;
        this.$axios.post(url, this.expPkg)
            .then(function(result){
                if(result.status){
                    _this.$alert(result.msg || (_this.id==null? _this.msg("创建成功"): _this.msg('更新成功')), _this.msg('提示'), {
                        confirmButtonText: _this.msg('确定'),
                        callback: action => {
                            if(result.status){
                                _this.$emit("closeDg", true);
                            }
                        }
                    });
                }else{
                    _this.$message(result.msg || (_this.id==null? _this.msg('创建失败'):_this.msg('更新失败')));
                }
               
            });
    }
  }
}
</script>

<style class="scss" scoped>


</style>
